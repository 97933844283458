import FinanceService from '@/services/finance.service'
import axios from 'axios'
import moment from 'moment/moment'

const wallet = {
  methods: {
    getCurrencies() {
      FinanceService.getHistory().then(async r => {
        this.total.usd = 0
        this.total.rub = 0
        this.history = r.data.map(historyItem => {
          if (historyItem.currency === '$' && historyItem.status === 'planned')
            this.total.usd = this.total.usd + Number(historyItem.amount)
          if (historyItem.currency === '₽' && historyItem.status === 'planned')
            this.total.rub = this.total.rub + Number(historyItem.amount)
          return {
            ...historyItem,
            inRub: historyItem.currency === '$' ? Number(this.currency * historyItem.amount).toFixed(2) : null,
          }
        })
      })
    },
    getUSDtoRUB() {
      axios.get(`https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/usd/rub.min.json`).then(r => {
        this.currency = r.data['rub']
        this.getCurrencies()
      })
    },
    formatDate(date) {
      return moment(date).format('DD.MM.YYYY HH:mm')
    },
  },
}

export default wallet