<template>
  <div class="tw-h-full tw-flex tw-flex-col">
    <h3 class="tw-mb-4 tw-font-bold tw-text-gray-600">
      {{ $t('Wallet') }}
    </h3>
    <div class="tw-flex tw-gap-6 tw-flex-grow tw-overflow-hidden">
      <div class="tw-w-3/12">
        <div class="tw-border tw-bg-white tw-p-6 tw-rounded-2xl">
          <h4 class="tw-mb-4 tw-font-bold tw-text-gray-600">Balance</h4>
          <div class="tw-flex tw-items-center">
            <div
              class="tw-w-10 tw-h-10 tw-flex tw-items-center tw-justify-center tw-bg-slate-100 tw-rounded-lg tw-text-2xl"
            >
              ₽
            </div>
            <span class="tw-ml-3 tw-text-3xl tw-text-slate-700 tw-tracking-tight tw-font-light tw-leading-6">{{
              total.rub.toLocaleString()
            }}</span>
          </div>
          <div class="tw-mt-4 tw-flex tw-items-center">
            <div
              class="tw-w-10 tw-h-10 tw-flex tw-items-center tw-justify-center tw-bg-slate-100 tw-rounded-lg tw-text-2xl"
            >
              $
            </div>
            <span class="tw-ml-3 tw-text-3xl tw-text-slate-700 tw-tracking-tight tw-font-light tw-leading-6">{{
              total.usd.toLocaleString()
            }}</span>
          </div>
        </div>
      </div>
      <div class="tw-flex-grow tw-h-full tw-overflow-auto board-scroll">
        <div class="tw-border tw-bg-white tw-p-6 tw-rounded-2xl">
          <h4 class="tw-mb-4 tw-font-bold tw-text-gray-600">Sprints payment history</h4>
          <app-collapse accordion>
            <app-collapse-item
              v-for="(sprint, index) in sprints"
              :key="index"
              :title="`Sprint #${sprint.name} (${sprint.tasks.length}) - ${sprint.project.title}`"
              size="sm"
              style="border-radius: 0 !important; box-shadow: none !important"
            >
              <ol>
                <li v-for="(task, index) in sprint.tasks" :key="index" class="mb-25">{{ task.subject }}</li>
              </ol>
            </app-collapse-item>
          </app-collapse>
        </div>
        <div class="tw-border tw-bg-white tw-p-6 tw-rounded-2xl tw-mt-6">
          <h4 class="tw-mb-4 tw-font-bold tw-text-slate-600">Sprints history</h4>
          <DataTable :value="history" class="p-datatable mb-0" responsiveLayout="scroll">
            <Column
              :body-style="{color: '#6e6b7b'}"
              body-class="pl-1"
              field="sprint"
              header="Sprint"
              header-class="pl-1 tw-text-gray-600 tw-bg-white"
            >
              <template #body="slotProps">
                {{ `#${slotProps.data.sprint.name} (${slotProps.data.sprint.project.title})` }}
              </template>
            </Column>
            <Column
              :body-style="{color: '#6e6b7b'}"
              field="amount"
              header="Amount"
              header-class="tw-text-gray-600 tw-bg-white"
            >
              <template #body="slotProps">
                <font-ai
                  v-if="['$'].includes(slotProps.data.currency)"
                  :icon="['fas', 'dollar-sign']"
                  :style="{'max-width': '10px'}"
                  class="font-small-3 mr-25"
                />
                <font-ai
                  v-else-if="['₽'].includes(slotProps.data.currency)"
                  :icon="['fas', 'ruble-sign']"
                  :style="{'max-width': '10px'}"
                  class="font-small-3 mr-25"
                />
                <font-ai
                  v-else-if="['TRY'].includes(slotProps.data.code)"
                  :icon="['fas', 'turkish-lira']"
                  :style="{'max-width': '10px'}"
                  class="font-small-3 mr-25"
                />
                <font-ai
                  v-else-if="['EUR'].includes(slotProps.data.code)"
                  :icon="['fas', 'euro-sign']"
                  :style="{'max-width': '10px'}"
                  class="font-small-3 mr-25"
                />
                <font-ai v-else :icon="['fas', 'coins']" :style="{'min-width': '15px'}" class="font-small-2 mr-25" />
                {{ slotProps.data.amount }} {{ slotProps.data.inRub ? ` (~ ${slotProps.data.inRub}р.)` : '' }}
              </template>
            </Column>
            <Column field="status" header="Status" header-class="tw-text-gray-600 tw-bg-white">
              <template #body="slotProps">
                <b-badge
                  :variant="`light-${slotProps.data.status === 'planned' ? 'warning' : 'success'}`"
                  class="font-small-2"
                  >{{ slotProps.data.status.toUpperCase() }}
                </b-badge>
              </template>
            </Column>
            <Column field="date" header="Date" header-class="tw-text-gray-600 tw-bg-white">
              <template #body="slotProps">
                {{ !slotProps.data.date ? '' : formatDate(slotProps.data.date) }}
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FinanceService from '@/services/finance.service';
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue';
import Column from 'primevue/column/Column';
import DataTable from 'primevue/datatable/DataTable';
import {BBadge} from 'bootstrap-vue';
import wallet from '@/mixins/mixWallet';

export default {
  mixins: [wallet],
  components: {
    BBadge,
    DataTable,
    Column,
    AppCollapse,
    AppCollapseItem,
  },
  data() {
    return {
      sprints: [],
      total: {
        rub: 0,
        usd: 0,
      },
      history: [],
    };
  },
  mounted() {
    FinanceService.getFinanceSprint().then(r => {
      this.sprints = r.data;
    });
    this.getUSDtoRUB();
  },
};
</script>

<style lang="scss" scoped></style>